import styled from "styled-components";

export const ContactsMapContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  background: linear-gradient(0deg, rgba(0, 23, 65, 1) 50%, rgba(0, 42, 102, 1) 100%);
`

export const SvgMap = styled.div`
  transition: 0.5s ease-in;
  transform-origin: 50% 50%;
  position: absolute;
  width: 1900px;
  height: 87vh;
  left: calc(50% - 950px);
  pointer-events: none;
  transform: ${props => props.matrix ? props.matrix : "scale(1)"};
  
  @media screen and (max-width: 1100px) {
    display: none;
  }
`

export const RegionImg = styled.img`
  transition: 0.5s ease-in;
  transform-origin: 50% 50%;
  position: absolute;
  width: 100%;
  height: auto;
  margin-top: 100px;
  opacity: ${props => (!props.selected && props.map) ? "1" : (props.selected ? "0.6" : "0")};
`

export const RegionBubble = styled.div`
  background: rgba(0, 23, 65, 0.4);
  border-radius: 15px;
  position: fixed;
  max-width: 300px;
  top: 15vh;
  padding: 15px 30px;
  left: calc(50% - 180px);
  transition: 0.5s ease-in;
  z-index: 3;
  opacity: ${props => props.selected ? "1" : "0"};
  pointer-events: none;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`