import React, {useState} from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";
import Form from "../../components/forms/Form";
import ContactBubbleCard from "../../components/cards/ContactBubbleCard";
import ContactRegionCard from "../../components/cards/ContactRegionCard";

import Col from "../../components/styled/grid/Col";
import {
    ContactsMapContainer,
    RegionImg,
    SvgMap
} from "../../components/styled/pages/about/contact/ContactComponents";
import {Header2} from "../../components/styled/typography/Typography";

import image from "/src/images/contact_map/world_map.png";
import cee from "/src/images/contact_map/cee.png";
import cis from "/src/images/contact_map/cis.png";
import dach from "/src/images/contact_map/dach.png";
import hq from "/src/images/contact_map/hq.png";
import other from "/src/images/contact_map/other.png";
import see from "/src/images/contact_map/see.png";
import middle_east from "/src/images/contact_map/middle-east.png";
import weu from "/src/images/contact_map/weu.png";

const Contact = ({data}) => {

    const {contact, contactForm} = data;
    const [matrix, setMatrix] = useState("");
    const [selected, setSelected] = useState("");

    const AnimationOnEnter = (props) =>{
        if(props === "hu"){
            //zoom-x="-150" zoom-y="480" zoom-size="3.6"
            setMatrix("matrix(3.6,0,0,3.6,-150,480)");
            setSelected("hu");
        } else if(props === "seu"){
            //zoom-x="-170" zoom-y="320" zoom-size="3"
            setMatrix("matrix(3,0,0,3,-170,320)")
            setSelected("seu");
        } else if(props === "cis"){
            //zoom-x="-760" zoom-y="300" zoom-size="2"
            setMatrix("matrix(2,0,0,2,-760,300)")
            setSelected("cis");
        } else if(props === "dach"){
            //zoom-x="-20" zoom-y="440" zoom-size="2.8"
            setMatrix("matrix(2.8,0,0,2.8,-20,440)")
            setSelected("dach");
        } else if(props === "middle_east"){
            //zoom-x="-530" zoom-y="-50" zoom-size="2.6"
            setMatrix("matrix(2.6,0,0,2.6,-530,-50)")
            setSelected("middle_east");
        } else if(props === "ceu"){
            //zoom-x="-120" zoom-y="480" zoom-size="3.3"
            setMatrix("matrix(3.3,0,0,3.3,-120,480)")
            setSelected("ceu");
        } else if(props === "weu"){
            //zoom-x="160" zoom-y="300" zoom-size="2.9"
            setMatrix("matrix(2.9,0,0,2.9,160,300)")
            setSelected("weu");
        } else if(props === "other"){
            //zoom-x="0" zoom-y="0" zoom-size="1"
            setMatrix("matrix(1,0,0,1,0,0)")
            setSelected("other");
        }
    }

    const AnimationOnLeave = () =>{
        setMatrix("scale(1)");
        setSelected("");
    }

    return (
        <Layout lang={contact.lang} seo={contact.SEO}>
            <ContactsMapContainer>
                <ContactBubbleCard
                    selected={selected ==="cis"}
                    title={contact.lang === "hu" ? "Kelet-Európa és Közép-Ázsia" : "Eastern Europe & Middle Asia"}
                    description={contact.lang === "hu" ? "Azerbajdzsán, Észtország, Georgia, Kazahsztán, Kirgizisztán, Lettország, Litvánia, Moldova, Mongólia, Örményország, Tadzsikisztán, Törökország, Türkmenisztán, Ukrajna, Üzbegisztán" : "Armenia, Azerbaijan, Estonia, Georgia, Kazakhstan, Kyrgyzstan, Latvia, Lithuania, Moldova, Mongolia, Tajikistan, Turkey, Turkmenistan, Ukraine, Uzbekistan"}
                />
                <ContactBubbleCard
                    selected={selected ==="ceu"}
                    title={contact.lang === "hu" ? "Közép-Európa" : "Central Europe"}
                    description={contact.lang === "hu" ? "Csehország, Lengyelország, Szlovákia" : "Czech Republic, Poland, Slovakia"}
                />
                <ContactBubbleCard
                    selected={selected ==="seu"}
                    title={contact.lang === "hu" ? "Balkán" : "Southeast Europe"}
                    description={contact.lang === "hu" ? "Albánia, Bosznia-Hercegovina, Bulgária, Görögország, Horvátország, Montenegró, Románia, Szerbia" : "Albania, Bosnia and Herzegovina, Bulgaria, Croatia, Greece, Kosovo, Montenegro, North Macedonia, Romania, Serbia, Slovenia"}
                />
                <ContactBubbleCard
                    selected={selected ==="dach"}
                    title={contact.lang === "hu" ? "DACH" : "DACH"}
                    description={contact.lang === "hu" ? "Ausztria, Németország, Svájc" : "Austria, Germany, Switzerland"}
                />
                <ContactBubbleCard
                    selected={selected ==="weu"}
                    title={contact.lang === "hu" ? "Nyugat-Európa" : "Western Europe"}
                    description={contact.lang === "hu" ? "Belgium, Franciaország, Luxemburg, Spanyolország" : "Belgium, France, Luxembourg, Spain"}
                />
                <ContactBubbleCard
                    selected={selected ==="middle_east"}
                    title={contact.lang === "hu" ? "Közel-Kelet" : "Middle East"}
                    description={contact.lang === "hu" ? "Dubai, Izrael" : "Dubai, Israel"}
                />
                <SvgMap matrix={matrix}>
                    <RegionImg src={image} alt="map" map/>
                    <RegionImg src={cee} alt="map" selected={selected === "ceu"}/>
                    <RegionImg src={cis} alt="map" selected={selected === "cis"}/>
                    <RegionImg src={dach} alt="map" selected={selected === "dach"}/>
                    <RegionImg src={hq} alt="map" selected={selected === "hu"}/>
                    <RegionImg src={other} alt="map" selected={selected === "other"}/>
                    <RegionImg src={see} alt="map" selected={selected === "seu"}/>
                    <RegionImg src={middle_east} alt="map" selected={selected === "middle_east"}/>
                    <RegionImg src={weu} alt="map" selected={selected === "weu"}/>
                </SvgMap>
                <SectionLayoutGrid contact customBackground="none" customPadding="noBottom" id="contacts">
                    <Col span={3} grid>
                        <ContactRegionCard
                            enter={()=>AnimationOnEnter("hu")}
                            leave={()=>AnimationOnLeave()}
                            email={contact.region_card[0].email}
                            region={contact.region_card[0].region}
                            tel={contact.region_card[0].tel}
                        />
                    </Col>
                    <Col span={3} grid>
                        <ContactRegionCard
                            enter={()=>AnimationOnEnter("seu")}
                            leave={()=>AnimationOnLeave()}
                            email={contact.region_card[1].email}
                            region={contact.region_card[1].region}
                            tel={contact.region_card[1].tel}
                        />
                    </Col>
                    <Col span={3} grid>
                        <ContactRegionCard
                            enter={()=>AnimationOnEnter("cis")}
                            leave={()=>AnimationOnLeave()}
                            email={contact.region_card[2].email}
                            region={contact.region_card[2].region}
                            tel={contact.region_card[2].tel}
                        />
                    </Col>
                    <Col span={3} grid>
                        <ContactRegionCard
                            enter={()=>AnimationOnEnter("dach")}
                            leave={()=>AnimationOnLeave()}
                            email={contact.region_card[3].email}
                            region={contact.region_card[3].region}
                            tel={contact.region_card[3].tel}
                        />
                    </Col>
                    <Col span={3} grid>
                        <ContactRegionCard
                            enter={()=>AnimationOnEnter("middle_east")}
                            leave={()=>AnimationOnLeave()}
                            email={contact.region_card[4].email}
                            region={contact.region_card[4].region}
                            tel={contact.region_card[4].tel}
                        />
                    </Col>
                    <Col span={3} grid>
                        <ContactRegionCard
                            enter={()=>AnimationOnEnter("ceu")}
                            leave={()=>AnimationOnLeave()}
                            email={contact.region_card[5].email}
                            region={contact.region_card[5].region}
                            tel={contact.region_card[5].tel}
                        />
                    </Col>
                    <Col span={3} grid>
                        <ContactRegionCard
                            enter={()=>AnimationOnEnter("weu")}
                            leave={()=>AnimationOnLeave()}
                            email={contact.region_card[6].email}
                            region={contact.region_card[6].region}
                            tel={contact.region_card[6].tel}
                        />
                    </Col>
                    <Col span={3} grid>
                        <ContactRegionCard
                            enter={()=>AnimationOnEnter("other")}
                            leave={()=>AnimationOnLeave()}
                            email={contact.region_card[7].email}
                            region={contact.region_card[7].region}
                            tel={contact.region_card[7].tel}
                        />
                    </Col>
                </SectionLayoutGrid>
                <SectionLayoutGrid customBackground="none" customMinHeight="medium" customPadding="smallerTop" id="contact-form">
                    <Col>
                        <Header2 color="white">
                            {contact.lang === "hu" ? "Kapcsolatfelvétel" : "Contact us"}
                        </Header2>
                    </Col>
                    {contact.balasys_card.map((item,index)=>(
                        <Col span={3} grid key={index}>
                            <ContactRegionCard
                                small
                                tel={item.description}
                                region={item.title}
                            />
                        </Col>
                    ))}
                    <Col>
                        <Form form={contactForm} lang={contact.lang} isContact={true}/>
                    </Col>
                </SectionLayoutGrid>
            </ContactsMapContainer>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleContact($locale: String) {
         contact: strapiContacts(lang: { eq: $locale }) {
          lang
          region_card {
            id
            region
            email
            tel
          }
          balasys_card {
            id
            title
            description
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
      contactForm: strapiContactUsForms(lang: { eq: $locale }) {
          Form {
            country
            email
            first_name
            help_dropdown
            id
            last_name
            message
            phone
            preffered_dropdown
          }
      }
   }
 `

export default Contact