import React from 'react';

import {RegionBubble} from "../styled/pages/about/contact/ContactComponents";
import {Body, Header3} from "../styled/typography/Typography";

const ContactBubbleCard = ({selected, title, description}) => {
    return (
        <RegionBubble selected={selected}>
            <Header3 color="primary-brand">
                {title}
            </Header3>
            <Body color="body-text">
                {description}
            </Body>
        </RegionBubble>
    );
};

export default ContactBubbleCard;