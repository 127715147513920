import styled from "styled-components";

export const ContactRegionCardWrapper = styled.div`
  display: grid;
  align-self: stretch;
  align-items: start;
  background-color: #173f6c;
  border-radius: 3px;
  box-shadow: 16px 16px 48px rgba(0, 0, 0, 0.1);
  transition: box-shadow 500ms;
  text-align: center;
  grid-gap: 0;
  padding: 15px;
  border-bottom: 5px solid var(--primary-brand);
  cursor: pointer;

  h3 {
    font-size: 1.3rem;
  }
  
  &:hover {
    box-shadow: 0px 0px 19px 0px #4fa0e2;
  }
  
`